import axios from "axios";
import { config } from "../commons/config.js";

// Set withCredentials to true for all requests
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] ="application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "http://localhost:3000";

async function activate(address, refCode){
    let apiUrl = config.apiurl +"/activate";
    return await axios
    //.get(apiUrl)
    .post(apiUrl, {
      address: address,
      refCode: refCode,
    })
    .then(response => {
        if(response.data.err){
          throw new Error(response.data.err.message);
        }
        return response.data.code;
    }).catch(error => {
        throw new Error(error);
    });
}

async function query(address){
    let apiUrl = config.apiurl +"/query/" + address;
    return await axios
    .get(apiUrl)
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.log(error);
        return false;
    });
}

async function nonce(){
    let apiUrl = config.apiurl +"/nonce";
    return await axios
    .get(apiUrl)
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.log(error);
        return false;
    });
}

async function verify(message, signature){
    let apiUrl = config.apiurl +"/verify";
    return await axios
    .post(apiUrl, {
      message: message,
      signature: signature
    })
    .then(response => {
        if(response.data.ok) {
          return true;
        }
        else {
          return false;
        }
    })
    .catch(error => {
        console.log(error);
        return false;
    });
}

async function logout(){
    let apiUrl = config.apiurl +"/logout";
    return await axios
    .get(apiUrl)
    .then(response => {
        return true;
    })
    .catch(error => {
        console.log(error);
        return false;
    });
}

async function me(){
    let apiUrl = config.apiurl +"/me";
    return await axios
    .get(apiUrl)
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.log(error);
        return false;
    });
}

function truncateEthAddress(address) {
    if(!address)
      return "";
    var truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
    var match = address.match(truncateRegex);
    if (!match)
        return address;
    return match[1] + "\u2026" + match[2];
};

export {
  activate,
  query,
  truncateEthAddress,
  me,
  logout,
  verify,
  nonce
}
