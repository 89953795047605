import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useAccount} from 'wagmi';
import { activate, truncateEthAddress, query } from "../../controller/referral";
import { useSearchParams } from "react-router-dom";

const ColorButton = styled((props: ButtonProps) => (
  <Button
    {...props}
  />
))(({ theme }) => ({
  margin: "10px",
  color: '#000',
  backgroundColor: '#ffffff',
  opacity: 0.8,
  '&:hover': {
    backgroundColor: '#ffffff',
    opacity: 1,
  },
  '&:disabled': {
    backgroundColor: '#ffffff',
    opacity: 0.5,
  },
}));

const RedditTextField = styled((props: TextFieldProps) => (
  <TextField
    {...props}
  />
))(({ theme }) => ({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#61DFFF',
    }
  },
  '& .MuiFilledInput-root': {
    border: '1px solid #61DFFF',
    width: '140px',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#ffffff',
    opacity: 0.8,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: '#ffffff',
      opacity: 1
    },
    '&.Mui-focused': {
      backgroundColor: '#ffffff',
      opacity: 1,
      boxShadow: '#61DFFF 0 0 0 2px',
      borderColor: '#61DFFF',
      color: '#61DFFF',
    },
    '&:disabled': {
      backgroundColor: '#ffffff',
      opacity: 0.7,
    },
  },
}));

function ActivateFormComponent({setCode, setIsActive, setInfo})  {

  const [refCode, setRefCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
      let queryRefCode = searchParams.get("refCode");
      if(queryRefCode)
        setRefCode(queryRefCode);
      return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //const debouncedRefCode = useDebounce(refCode, 500);

  const { address, isConnected } = useAccount();

  return(
    <form onSubmit={(e) => {
      e.preventDefault();
      setIsLoading(true);
      activate(address, refCode)
        .then((code) => {
          setIsLoading(false);
          toast.success("Address "+truncateEthAddress(address)+" activated!");
          setCode(code);
          query(address)
            .then((infoQuery) => {
              setInfo(infoQuery);
              setIsActive(true);
            })
            .catch((error) => {
              toast.error("Please reload the page" + error.message);
            })
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.data) {
            toast.error(`${error.response.data.err.message} 🙅`);
          } else {
            toast.error(error.message);
          }
        })
      }
    }>
      <div className="text-center" hidden={!isConnected} >
        <FormControl>
          <RedditTextField
            type="text"
            label={"Referral Code"}
            id="refCode"
            variant="filled"
            disabled={isLoading}
            placeholder="4t1zfZbKAB"
            onChange={(e) => setRefCode(e.target.value)}
            value={refCode}
            className="text-darker px-3 py-2 w-max"
          />
        </FormControl>
        {refCode.length !== '' &&
          <ColorButton
            variant="contained"
            size="large"
            type="submit"
            hidden={refCode === ''}
            disabled={isLoading || refCode.length < 10}
          >{isLoading ? "Activating..." : "Activate!"}
          </ColorButton>
        }
      </div>
    </form>
  );
}

export default ActivateFormComponent;
