import { AppBar, Box, Container } from '@mui/material';
import { lineaLogoW } from '../../commons/assets.js';
import { useState, useEffect } from "react";

import { Web3Button } from '@web3modal/wagmi/react';
import "./index.css";

function NavBar() {
  const [fixedMenu, setFixedMenu] = useState(false);

  const handleScroll = () => {
    if (0 < document.documentElement.scrollTop) {
      setFixedMenu(true);
    } else {
      setFixedMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" elevation={0} sx={{ backgroundColor : '#000'}}>
        <Container maxWidth="lg" sx={{ padding: '18px 0' }}>
          <div className="flex flex-wrap justify-around items-center">
            <div className="justify-self-start">
              <img src={lineaLogoW} alt="Linea logo" width="150px"/>
            </div>
            <div className="justify-self-center grow text-center text-primary">
              <a href="https://docs.linea.build/use-mainnet/linea-surge" rel="noopener noreferrer" target="_blank">Doubts? read the docs!</a>
            </div>
            <div className="justify-self-end">
              <w3m-button size="sm" balance="show" />
            </div>
          </div>
          <div className="description" style={{color: "#FFFFFF", margin: 30+"px"}}>
            To activate your wallet and start collecting <strong>LXP-L points</strong>, you have to provide a referral code. <strong>Make sure you activate a Referral Code on the correct wallet!</strong>
          </div>
        </Container>
      </AppBar>
    </Box>
  );
}

export default NavBar;
