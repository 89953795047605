import { useEffect, useState } from "react";
import HomePage from './components/HomePage';
import { toast } from "react-toastify";

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'

import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { mainnet } from 'viem/chains'
import { linea } from './commons/linea.js'
import { lineaLogoB, lineaIconB, ethIcon } from './commons/assets.js'

import { SiweMessage } from 'siwe'
import { createSIWEConfig } from '@web3modal/siwe'
import type { SIWECreateMessageArgs, SIWEVerifyMessageArgs } from '@web3modal/siwe'
import { me, nonce, logout, verify } from './controller/referral.js'

import './fonts.css'

import "./styles/global.css";

//TODO: to be hidden or moved to config
const projectId = 'b79d032e55eaf0a05d1286b8798c705a'

const { chains, publicClient } = configureChains(
  [mainnet, linea],
  [walletConnectProvider({ projectId }), publicProvider()]
)

const metadata = {
  name: 'Linea Surge',
  description: 'Linea Surge - Referrals',
  url: window.location.host,
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const wagmiConfig = createConfig({
  autoConnect: false,
  connectors: [
    new WalletConnectConnector({ chains, options: { projectId, showQrModal: true, metadata } }),
    new EIP6963Connector({ chains })
  ],
  publicClient
})

/* Create a SIWE message */
function createMessage({ nonce, address, chainId }: SIWECreateMessageArgs){
  const message = new SiweMessage({
    version: '1',
    domain: window.location.host,
    uri: window.location.origin,
    address,
    chainId,
    nonce,
    statement: 'Verify wallet ownership for the Linea Surge Referrals'
  })

  return message.prepareMessage()
}

/* Function that returns the user's session */
async function getSession(){
  const session = await me()
  if (!session){
    toast.error("Please connect your wallet and verify your ownership");
    return;
  } else {
    const { address, chainId } = session
    return { address, chainId }
  }
}

async function getNonce(){
  const nonceRes = await nonce()
  if (!nonceRes) {
    toast.error('Failed to get nonce for the signature!');
    return;
  } else {
    return nonceRes;
  }
}

async function signOut(){

  const signout = await logout()
  if (!signout){
    toast.error('Failed to disconnect the wallet! Refresh the page');
    return;
  }
  return signout;
}

/* Use your SIWE server to verify if the message and the signature are valid */
async function verifyMessage({ message, signature }: SIWEVerifyMessageArgs){
  try {
    const isValid = await verify(message, signature)
    return isValid
  } catch (error) {
    return false
  }
}

/* Create a SIWE configuration object */
const siweConfig = createSIWEConfig({
  createMessage,
  getNonce,
  getSession,
  verifyMessage,
  signOut
})

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  siweConfig,
  defaultChain: linea,
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f'
  ],
  termsConditionsUrl: 'https://consensys.net/beta-terms/',
  privacyPolicyUrl: 'https://consensys.net/privacy-policy/',
  themeVariables: {
    '--w3m-color-fg-1': 'rgb(150,150,150)',
    '--w3m-logo-image-url': lineaLogoB,
    '--w3m-background-color': '#FFFFFF',
    '--w3m-accent-color': '#3b3b3b',
    '--w3m-accent-fill-color': '#FFFFFF',
    '--w3m-font-family': 'AtypDisplay',
    '--w3m-z-index': 999999
  },
  chainImages: {
       5: ethIcon,
       59140: lineaIconB,
       59141: lineaIconB,
       59144: lineaIconB,
  }
})

function App() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);


  return (
    <>
      {ready ? (
        <WagmiConfig config={wagmiConfig}>
          <HomePage/>
        </WagmiConfig>
      ) : null}
    </>
  )
};

export default App;
