import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  GabIcon,
  GabShareButton,
  HatenaIcon,
  HatenaShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
  XIcon,
} from "react-share";

import { config } from "../../commons/config.js";

import "./share.css";

function ShareComponent(props) {

  const refCode = props.code;

  const shareUrl = config.baseShareUrl+"/?refCode="+refCode;
  //const shareUrl = "http://localhost:3000/?refCode="+refCode;
  const title = "Join Linea Voyage: The Surge";

  return (
    <div className="shareContainer">

      <div className="shareSocials">
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="shareSocials_share-button"
        >
          <XIcon size={32} round />
        </TwitterShareButton>
      </div>

      <div className="shareSocials">
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="shareSocials_share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </div>

      <div className="shareSocials">
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="shareSocials_share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>

      <div className="shareSocials">
        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="shareSocials_share-button"
        >
          <RedditIcon size={32} round />
        </RedditShareButton>
      </div>

      <div className="shareSocials">
        <FacebookShareButton
          url={shareUrl}
          className="shareSocials_share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>

      <div className="shareSocials">
        <LineShareButton
          url={shareUrl}
          title={title}
          className="shareSocials_share-button"
        >
          <LineIcon size={32} round />
        </LineShareButton>
      </div>

      <div className="shareSocials">
        <WeiboShareButton
          url={shareUrl}
          title={title}
          image={`${String(window.location)}/linea_surge.jpg`}
          className="shareSocials_share-button"
        >
          <WeiboIcon size={32} round />
        </WeiboShareButton>
      </div>

      <div className="shareSocials">
        <PocketShareButton
          url={shareUrl}
          title={title}
          className="shareSocials_share-button"
        >
          <PocketIcon size={32} round />
        </PocketShareButton>
      </div>

      <div className="shareSocials">
        <MailruShareButton
          url={shareUrl}
          title={title}
          className="shareSocials_share-button"
        >
          <MailruIcon size={32} round />
        </MailruShareButton>
      </div>

      <div className="shareSocials">
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="shareSocials_share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>

      <div className="shareSocials">
        <LinkedinShareButton
          url={shareUrl}
          className="shareSocials_share-button"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div className="shareSocials">
        <PinterestShareButton
          url={String(window.location)+"/?refCode="+refCode}
          media={`${String(window.location)}/linea_surge.jpg`}
          className="shareSocials_share-button"
        >
          <PinterestIcon size={32} round />
        </PinterestShareButton>
      </div>

      <div className="shareSocials">
        <VKShareButton
          url={shareUrl}
          image={`${String(window.location)}/linea_surge.jpg`}
          className="shareSocials_share-button"
        >
          <VKIcon size={32} round />
        </VKShareButton>
      </div>

      <div className="shareSocials">
        <OKShareButton
          url={shareUrl}
          image={`${String(window.location)}/linea_surge.jpg`}
          className="shareSocials_share-button"
        >
          <OKIcon size={32} round />
        </OKShareButton>
      </div>

      <div className="shareSocials">
        <GabShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={640}
          className="shareSocials_share-button"
        >
          <GabIcon size={32} round />
        </GabShareButton>
      </div>

      <div className="shareSocials">
        <TumblrShareButton
          url={shareUrl}
          title={title}
          className="shareSocials_share-button"
        >
          <TumblrIcon size={32} round />
        </TumblrShareButton>
      </div>

      <div className="shareSocials">
        <LivejournalShareButton
          url={shareUrl}
          title={title}
          description={shareUrl}
          className="shareSocials_share-button"
        >
          <LivejournalIcon size={32} round />
        </LivejournalShareButton>
      </div>

      <div className="shareSocials">
        <ViberShareButton
          url={shareUrl}
          title={title}
          className="shareSocials_share-button"
        >
          <ViberIcon size={32} round />
        </ViberShareButton>
      </div>

      <div className="shareSocials">
        <WorkplaceShareButton
          url={shareUrl}
          quote={title}
          className="shareSocials_share-button"
        >
          <WorkplaceIcon size={32} round />
        </WorkplaceShareButton>
      </div>

      <div className="shareSocials">
        <InstapaperShareButton
          url={shareUrl}
          title={title}
          className="shareSocials_share-button"
        >
          <InstapaperIcon size={32} round />
        </InstapaperShareButton>
      </div>

      <div className="shareSocials">
        <HatenaShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="shareSocials_share-button"
        >
          <HatenaIcon size={32} round />
        </HatenaShareButton>
      </div>
    </div>
  );
}

export default ShareComponent;
