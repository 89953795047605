import React, { useState } from "react";
import { useAccount } from 'wagmi';
import { truncateEthAddress } from "../../controller/referral";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { config } from "../../commons/config.js";

import ShareComponent from "../ReferralComponent/share.js";

function QueryComponent(props)  {

  const { address, isConnected } = useAccount();

  const [isActive, setIsActive] = useState(false);
  const [isShareOpened, setIsShareOpened] = useState(false);

  var info = props.info;

  function toggleShare() {
    setIsShareOpened(wasOpened => !wasOpened);
  }

  return(
    <div className="min-w-250">
      <div className="main-card dot flex justify-between p-4 md:p-[26px] gap-8 sm:flex-col-reverse sm:gap-4">
        <div>
          <h3 className="card-title">Your address is ACTIVE!</h3>
          <p className="card-subtitle text-primary mt-1 md:mt-2 xs:w-[290px] md:w-[390px] lg:w-[490px]">
            <strong>{truncateEthAddress(address)} is already eligible</strong> to collect LXP-L since {
              info.timestamp ?
              new Intl.DateTimeFormat('en-US', {
                dateStyle: 'full',
                timeStyle: 'long',
                timeZone: 'GMT',
              }).format(new Date(info.timestamp)) : ""}
          </p>
          <p className="card-subtitle mt-1 md:mt-2 xs:w-[290px] md:w-[390px] lg:w-[490px]">
            You can verify the LXP-L collected within The Surge Program by looking in the <strong>OpenBlock Surge Dashboard</strong>. You have your own Referral Code that you can <strong>share with friends</strong> to increase the number of LXP-L you can collect.
          </p>
          <p className="text-white card-subtitle mt-1 md:mt-2 xs:w-[290px] md:w-[390px] lg:w-[490px]">Your Own Referral Code:
          </p>
          <h3 className="card-title text-center" style={{marginTop: 15 + 'px'}}><strong>{info.code}</strong></h3>
          <p className="text-white card-subtitle mt-1 md:mt-2 xs:w-[290px] md:w-[390px] lg:w-[490px]">Number of addresses referred: {info.referrals ? info.referrals.length : ""}
          </p>
          <div className="text-primary text-center pt-2 flex justify-between">
            <p className="flex justify-center">
              <button
                onClick={() => {
                  navigator.clipboard.writeText(config.baseShareUrl+"/?refCode="+info.code)
                  .then(
                    toast.success("Referral Code copied in the clipboard!")
                  )}
                }
              >Copy your code
              </button>
            </p>
            <p className="flex justify-center">
              <button
                onClick={() => {
                    toggleShare();
                  }
                }
              >{isShareOpened ? 'Hide Share' : 'Share Code' }
              </button>
            </p>
          </div>
          <div className="flex justify-center">
            { isShareOpened && (
              <div className="flex justify-between mt-12" >
                <ShareComponent code={info.code}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QueryComponent;
