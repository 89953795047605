import React, { useState } from "react";
import ActivateFormComponent from "./activateForm.js";
import { useAccount } from 'wagmi';
import { truncateEthAddress } from "../../controller/referral";


function ActivateComponent(props)  {
  const { address } = useAccount();
  const [code, setCode] = useState("");

  var codeBlock = code ? "This is your new Referral Code: "+code : ""

  return(
    <div className="min-w-250">
      <div className="main-card dot flex justify-between p-4 md:p-[26px] gap-8 sm:flex-col-reverse sm:gap-4">
        <div>
          <h3 className="card-title">Activate Your Address here</h3>
          <p className="card-subtitle text-primary mt-1 md:mt-2 xs:w-[290px] md:w-[390px] lg:w-[490px]">
            <strong>{truncateEthAddress(address)} is not active!</strong>
          </p>
          <p className="card-subtitle mt-1 md:mt-2 xs:w-[290px] md:w-[390px] lg:w-[490px]">
            To start collecting LXP-L with the liquidity provided by {truncateEthAddress(address)} on Linea Protocols that are part of the Surge program, you have to obtain and <strong>type here a Referral Code</strong> and click Activate button:
          </p>
          <div className="text-white text-center pt-2 flex justify-between xs:w-[290px] md:w-[390px] lg:w-[490px]">
            <div className="flex justify-center xs:w-[290px] md:w-[390px] lg:w-[490px]">
              <ActivateFormComponent setCode={setCode} setIsActive={props.setIsActive} setInfo={props.setInfo}/>
            </div>
          </div>
          <div className="text-primary text-center pt-2 flex justify-between">
            <p className="flex justify-center">
                {codeBlock}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivateComponent;
