import styled from '@emotion/styled';
import { Box, Container } from '@mui/material';
import { heroBgImage, heroOverlay } from '../../commons/assets.js';

const Hero = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${heroBgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        position: "relative",
        'img': {
          position: 'absolute',
          right: {xs: '-290px', md: "0"},
          bottom: {xs: '130px', md: '50px'},
          transform: {xs: 'rotate(90deg)', md: "unset"},
          maxWidth: 'fit-content'
        }
      }}
      style={{maxHeight: 500+"px"}}
    >
      <img src={heroOverlay} sx={{width: 100+"%"}} alt="hero overlay" />
      <Container maxWidth="lg">
        <HeroWrapper>
          <div className="hero-content">
            <p className="name">A Point Program to incentivize Liquidity among the Linea Ecosystem</p>
            <h1 className="title">Linea Voyage: The Surge</h1>
            <p className="description">
               Read more <a style={{color: "#61DFFF"}} href="https://linea.mirror.xyz/NzFOD8A8tvKIqqjpuJFxepkminihbCzu1n8q7FOoKfA" rel="noopener noreferrer" target="_blank" className="text-primary">on our blog</a>.
            </p>

          </div>
        </HeroWrapper>
      </Container>
    </Box>
  );
};

export default Hero;

const HeroWrapper = styled.div`
  color: #000;
  padding-top: 6rem 0;

  @media (min-width: 600px) {
    position: relative;
    padding: 6rem 0;
  }

  @media (max-width: 600px) {
    position: relative;
    padding: 15rem 0;
  }

  a {
    color: #2c56dd;
    text-decoration: none;
  }

  .hero-content {
    margin-top: 40px;
    @media (min-width: 600px) {
      margin-top: 80px;
    }
  }

  .name {
    font-family: AtypText;
    text-transform: uppercase;
    color: #61DFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    margin: 0;

    @media (min-width: 600px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .title {
    font-family: AtypDisplay;
    font-size: 46px;
    line-height: 1.1;
    font-weight: 400;
    color: #FFF;
    margin: 1rem 0;
    letter-spacing: -1px;

    @media (min-width: 600px) {
      font-size: 64px;
    }
  }

  .description {
    font-family: AtypText;
    font-size: 16px;
    color: #C0C0C0;
    margin-top: 30px;
    max-width: calc(100% - 150px);

    @media (min-width: 600px) {
      font-size: 18px;
    }
  }
`;
