export const linea = {
  id: 59144,
  name: 'Linea',
  network: 'linea',
  nativeCurrency: {
    decimals: 18,
    name: 'LineaETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.linea.build'] },
    default: { http: ['https://rpc.linea.build'] },
  },
  blockExplorers: {
    etherscan: { name: 'Linea Explorer', url: 'https://lineascan.build' }
  },
  contracts: {
  }
}
