import { truncateEthAddress, query } from "../../controller/referral";

import { useState, useEffect } from "react";

import ShareComponent from "../ReferralComponent/share.js";

import "./index.css";

// import AppExplanations from "../AppExplanations";
import ActivateComponent from "../ReferralComponent/activate.js";
import QueryComponent from "../ReferralComponent/query.js";
import { ToastContainer } from "react-toastify";

import { useAccount } from 'wagmi';

// import HCaptcha from "@hcaptcha/react-hcaptcha";

import { openblockLogo } from '../../commons/assets.js'

import Hero from './Hero';
import NavBar from './NavBar';

function HomePage() {

  const { address, isConnected } = useAccount();

  const [isActive, setIsActive] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
      async function fetchData() {
        if(!address) return;
        let infoQuery = await query(address);
        setInfo(infoQuery);
        setIsActive(infoQuery.code? true:false);
        return;
      }
      fetchData();
      return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  var body = isConnected ? (isActive ? <QueryComponent info={info}/> : <ActivateComponent info={info} setInfo={setInfo} setIsActive={setIsActive} />) : null;

  return (
    <div className="w-screen">
      <NavBar/>
      <div className="bg-dark overflow-hidden fontClass --font-atyp --font-atyp-text flex flex-col min-h-screen">
      <ToastContainer hideProgressBar={true} info={info} />
        <div className="py-8 lg:py-16">
          <Hero />
          <header className="pt-4 pb lg:pt-6">
            <div className="card-title text-center text-primary xl:py-10 lg:py-10 sm:py-24 md:py-16 py-16" hidden={isConnected}>Connect your wallet to start...</div>
            <div className="py lg:py" hidden={!isConnected}><p className="p md:p-[26px] text-center text-primary text-[12px] leading-[17px] tracking-wider uppercase font-atyptext">Connected as {truncateEthAddress(address)}</p><p className="card-title text-center">Welcome to The Surge!</p></div>
          </header>
        </div>
        <div className="py-4" hidden={!isConnected}>
          <div className="place-items-center grid gap-4 grid-cols-1 sm:grid-cols-1 sm:gap-7 lg:grid-cols-1">
            {body}
          </div>
        </div>
        <div className="container py-6"></div>
        <div className="mt-auto">
          <p className="text-center text-white text-[12px] leading-[17px] tracking-wider uppercase font-atyptext">
            Supported by OpenBlock Labs. Check <strong><a href="https://www.openblocklabs.com/app/linea" rel="noreferrer noopener" target="_blank">The Surge Dashboard</a></strong> for details about the program:
          </p>
          <div className="flex flex-row justify-around h-300 my-6">
            <div className="grow mx-12 h-300"></div>
            <div className="grow-0 self-center mx-12 h-300">
              <a href="https://www.openblocklabs.com" rel="noreferrer noopener" target="_blank">
                <img
                  src={openblockLogo}
                  alt={"OpenBlock Labs Linea Surge Dashboard"}
                  width="100"
                />
              </a>
            </div>
            <div className="grow mx-12 h-300"></div>
          </div>
        </div>
        <div className="container py-6"></div>
        <div className="mt-auto">
          <p className="text-center text-white text-[12px] leading-[17px] tracking-wider uppercase font-atyptext">
            A modest Web App built with love by{" "}
            <a
              href="https://www.consensys.net"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              ConsenSys
            </a>{" "}
            with Wagmi, WalletConnect, Infura and Metamask.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
